import CleaningTransactionsImpl, {
  useNormalizedAccountsBeingCleaned,
} from "modules/insights/components/CleaningTransactions/CleaningTransactionsImpl";
import { useRefreshBalanceHistoryQueries } from "modules/insights/queries/useBalanceHistory";
import { useNormalizedAccountsAugmented } from "modules/insights/queries/useNormalizedAccountsAugmented";
import { FC } from "react";

type Props = {
  className?: string;
};

const AccountsOverviewCleaningTransactionsRenderer: FC<Props> = ({ className }) => {
  const normalizedAccountsAugmented = useNormalizedAccountsAugmented({
    filters: {
      includeHighbeam: true,
    },
  });

  const refreshBalanceHistoryQueries = useRefreshBalanceHistoryQueries();

  const normalizedAccountsBeingCleaned = useNormalizedAccountsBeingCleaned({
    normalizedAccountsAugmented: normalizedAccountsAugmented,
    onFinishedCleaning: refreshBalanceHistoryQueries,
  });

  if (normalizedAccountsBeingCleaned.length === 0) {
    return null;
  }

  return (
    <CleaningTransactionsImpl
      normalizedAccountsBeingCleaned={normalizedAccountsBeingCleaned}
      className={className}
    />
  );
};

export default AccountsOverviewCleaningTransactionsRenderer;
