import { keepPreviousData } from "@tanstack/react-query";
import dayjs from "dayjs";
import PlaidAccountsTableEmpty from "modules/plaid/components/PlaidAccountsTableEmpty";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyState from "ui/data-display/EmptyState";
import SectionV2 from "ui/data-display/SectionV2";
import ButtonLink from "ui/inputs/ButtonLink";
import DatePicker from "ui/inputs/DatePicker";
import Listbox from "ui/inputs/Listbox";
import TextInputV2 from "ui/inputs/TextInputV2";
import { DEFAULT_PAGE_PARAM_KEY } from "ui/navigation/pagination/config";
import PaginationGenerator from "ui/navigation/pagination/PaginationGenerator";
import PaginationSliceIndicator from "ui/navigation/pagination/PaginationSliceIndicator";
import {
  useDateValueFilter,
  useStringValueFilter,
} from "ui/navigation/pagination/use-filters-with-page-reset";
import usePage from "ui/navigation/pagination/usePage";
import { scrollToTopOfDashboardPage } from "ui/navigation/ScrollToTopOnNavigate";
import AmountCell from "ui/table/cells/AmountCell";
import DateTimeCell from "ui/table/cells/DateTimeCell";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import TextCell from "ui/table/cells/TextCell";
import PaginatedTableContainer from "ui/table/PaginatedTableContainer";
import Table, { TableColumnAlignment } from "ui/table/Table";
import { Heading4, Paragraph } from "ui/typography";
import useMountEffect from "utils/customHooks/useMountEffect";
import useSearchParamDayjs from "utils/search-params/useSearchParamDayjs";
import variants from "utils/ts/variants";

import { enrichedTransactionsQueryHooks } from "../queries/useEnrichedTransactions";
import { useNormalizedAccountsAugmented } from "../queries/useNormalizedAccountsAugmented";
import { AccountType } from "../reps/AccountType";
import { EnrichedTransactionRep } from "../reps/EnrichedTransactionRep";

import CleaningTransactionsImpl, {
  useNormalizedAccountsBeingCleaned,
} from "./CleaningTransactions/CleaningTransactionsImpl";
import EnrichedTransactionCounterpartyBar from "./EnrichedTransactionCounterpartyBar";
import EnrichedTransactionFlexpane from "./EnrichedTransactionFlexpane";
import NormalizedAccountAugmentedBar from "./NormalizedAccountRepBar/NormalizedAccountAugmentedBar";

const PLAID_ACCOUNT_PARAM_KEY = "plaidAccountId";
const ENRICHED_ACCOUNT_PARAM_KEY = "account";

type Props = {
  accountType?: AccountType;
  includeHighbeam?: boolean;
};

const EnrichedTransactionsSection: FC<Props> = ({ accountType, includeHighbeam = true }) => {
  const [search, setSearch] = useStringValueFilter({ paramKey: "search" });
  const [now] = useState(() => dayjs());
  const [dateRangeStart, setDateRangeStart] = useSearchParamDayjs(
    "start",
    now.subtract(12, "months")
  );
  const [dateRangeEndInclusive, setDateRangeEndInclusive] = useDateValueFilter({
    paramKey: "end",
    defaultValue: now,
  });
  const [selectedAccountId, setSelectedAccountId] = useStringValueFilter({
    paramKey: ENRICHED_ACCOUNT_PARAM_KEY,
  });
  const [page] = usePage();

  // Scroll to top  when page changes.
  useEffect(() => {
    scrollToTopOfDashboardPage();
  }, [page]);

  const normalizedAccountsAugmented = useNormalizedAccountsAugmented({
    filters: {
      accountType: accountType,
      includeHighbeam: includeHighbeam,
    },
  });

  // Map to normalized account to make it easier for components that don't know about "normalized accounts" to redirect to this page.
  const [searchParams, setSearchParams] = useSearchParams();
  useMountEffect(() => {
    const plaidAccountId = searchParams.get(PLAID_ACCOUNT_PARAM_KEY);
    if (plaidAccountId) {
      const normalizedAccount = normalizedAccountsAugmented.find(
        (na) => na.source === "Plaid" && na.sourceAccountId === plaidAccountId
      );
      if (normalizedAccount) {
        setSearchParams(
          (currentParams) => {
            currentParams.set(ENRICHED_ACCOUNT_PARAM_KEY, normalizedAccount.id); // Not using `setSelectedAccountId` because it can't replace state.
            currentParams.delete(PLAID_ACCOUNT_PARAM_KEY); // Key is no longer needed.
            return currentParams;
          },
          { replace: true }
        );
      }
    }
  });

  const normalizedAccountIds = useMemo(() => {
    return normalizedAccountsAugmented.map((na) => na.id);
  }, [normalizedAccountsAugmented]);

  const hasNormalizedAccounts = normalizedAccountIds.length > 0;

  const selectedNormalizedAccount = useMemo(
    () => normalizedAccountsAugmented.find((na) => na.id === selectedAccountId),
    [normalizedAccountsAugmented, selectedAccountId]
  );

  const {
    data: enrichedTransactions,
    isPlaceholderData,
    refetch: refetchEnrichedTransactions, // NB(alex): Make sure this isn't accidentally getting called when disabled! https://linear.app/highbeam/issue/HB-7405/cleaning-transactions-empty-state-not-showing-for-connected
  } = enrichedTransactionsQueryHooks.useQuery({
    params: {
      dateRangeStart: dateRangeStart.format("YYYY-MM-DD"),
      dateRangeEndInclusive: dateRangeEndInclusive.format("YYYY-MM-DD"),
      normalizedAccountIds: selectedAccountId ? [selectedAccountId] : normalizedAccountIds,
      textQuery: search || undefined,
      page: page,
      pageSize: 20,
    },
    placeholderData: keepPreviousData,
    enabled: hasNormalizedAccounts,
  });

  const normalizedAccountsBeingCleaned = useNormalizedAccountsBeingCleaned({
    normalizedAccountsAugmented,
    onFinishedCleaning: useCallback(() => {
      refetchEnrichedTransactions();
    }, [refetchEnrichedTransactions]),
  });

  // TODO(alex): Andrew's going to give me an endpoint to be able to fetch by `normalizedTransactionId` soon, so we should control the modal with that once it's ready.
  const [enrichedTransaction, setEnrichedTransaction] = useState<EnrichedTransactionRep | null>(
    null
  );

  return (
    <SectionV2 variant="dashboard-page" className="@container">
      <EnrichedTransactionFlexpane
        enrichedTransaction={enrichedTransaction ?? undefined}
        onClose={() => setEnrichedTransaction(null)}
      />

      {normalizedAccountsBeingCleaned.length > 0 && (
        <CleaningTransactionsImpl
          normalizedAccountsBeingCleaned={normalizedAccountsBeingCleaned}
          className="mb-8"
        />
      )}

      <div className="grid grid-cols-2 gap-4 @5xl:flex">
        <div className="col-span-2 flex-1 @xl:col-span-1">
          <Listbox
            value={selectedAccountId}
            onValueChange={(newValue) => setSelectedAccountId(newValue ?? "")}
          >
            <Listbox.Trigger>
              {selectedNormalizedAccount ? (
                <NormalizedAccountAugmentedBar
                  className="h-fit text-sm"
                  normalizedAccountAugmented={selectedNormalizedAccount}
                />
              ) : (
                "Show transactions from"
              )}
            </Listbox.Trigger>

            <Listbox.Menu>
              <Listbox.EmptyItem className="py-4 text-grey-600">Clear selection</Listbox.EmptyItem>

              {normalizedAccountsAugmented.map((normalizedAccountAugmented) => {
                return (
                  <Listbox.Item
                    key={normalizedAccountAugmented.id}
                    value={normalizedAccountAugmented.id}
                  >
                    <NormalizedAccountAugmentedBar
                      className="text-sm"
                      normalizedAccountAugmented={normalizedAccountAugmented}
                    />
                  </Listbox.Item>
                );
              })}
            </Listbox.Menu>
          </Listbox>
        </div>

        <div className="col-span-2 flex-1 @xl:col-span-1">
          <TextInputV2 value={search} onChange={setSearch} placeholder="Search for transaction" />
        </div>

        <div className="col-span-2 flex items-center gap-x-4">
          <DatePicker
            value={dateRangeStart.toDate()}
            onChange={(date) => date && setDateRangeStart(dayjs(date))}
            label="From"
            variant="start-date"
            startDate={dateRangeStart.toDate()}
            endDate={dateRangeEndInclusive.toDate()}
          />
          <DatePicker
            value={dateRangeEndInclusive.toDate()}
            onChange={(date) => date && setDateRangeEndInclusive(dayjs(date))}
            label="To"
            variant="end-date"
            startDate={dateRangeStart.toDate()}
            endDate={dateRangeEndInclusive.toDate()}
            minDate={dateRangeStart.toDate()}
          />
        </div>
      </div>

      <div className="mt-8">
        {enrichedTransactions?.results && enrichedTransactions.results.length === 0 ? (
          <EmptyState
            className="w-full"
            variant="card"
            body={<EmptyState.PrimaryText>No transactions found</EmptyState.PrimaryText>}
          />
        ) : (
          <>
            {hasNormalizedAccounts && enrichedTransactions && (
              <div className="flex items-center justify-between">
                <Paragraph className="text-sm text-grey-600">
                  Transactions are refreshed daily.
                </Paragraph>
                <PaginationSliceIndicator
                  page={page}
                  pageSize={enrichedTransactions.pageInfo.pageSize}
                  totalCount={enrichedTransactions.pageInfo.totalCount}
                />
              </div>
            )}

            {hasNormalizedAccounts ? (
              <PaginatedTableContainer isSwitchingPage={isPlaceholderData} className="mt-4">
                <Table
                  data={enrichedTransactions?.results}
                  rowKey={(txn) => txn.rawTransactionId}
                  onRowClick={
                    // NB(alex): Flexpane crashes if you click on a row that's loading...
                    enrichedTransactions?.results ? (txn) => setEnrichedTransaction(txn) : undefined
                  }
                  columns={[
                    {
                      title: "Date",
                      width: 160,
                      cellRender: (txn) => {
                        if (txn.at) {
                          return <DateTimeCell date={txn.at} />;
                        }
                        const d = dayjs(txn.date);
                        if (d.isSame(now, "year")) {
                          return <TextCell>{d.format("MMM D")}</TextCell>;
                        }
                        return <TextCell>{d.format("MMM D, YYYY")}</TextCell>;
                      },
                    },
                    {
                      title: "To / From",
                      cellRender: (txn) => {
                        return (
                          <TextCell className="font-medium text-grey-800">
                            <EnrichedTransactionCounterpartyBar enrichedTransaction={txn} />
                          </TextCell>
                        );
                      },
                    },
                    {
                      title: "Account",
                      cellRender: (txn) => {
                        const normalizedAccount = normalizedAccountsAugmented?.find(
                          (na) => na.id === txn.normalizedAccountId
                        );
                        if (!normalizedAccount) {
                          return <NotFoundCell />;
                        }
                        return (
                          <NormalizedAccountAugmentedBar
                            className="text-sm"
                            normalizedAccountAugmented={normalizedAccount}
                          />
                        );
                      },
                    },
                    {
                      title: "Amount",
                      align: TableColumnAlignment.RIGHT,
                      cellRender: (txn) => {
                        const amountInCents = Number(txn.amount.amount) * 100;
                        return (
                          <AmountCell
                            cents={amountInCents}
                            direction={amountInCents > 0 ? "positive" : "negative"}
                          />
                        );
                      },
                    },
                  ]}
                />
                {enrichedTransactions && (
                  <div className="my-8 ml-auto w-fit">
                    <PaginationGenerator
                      page={page}
                      totalPages={enrichedTransactions.pageInfo.totalPages}
                      pageParamKey={DEFAULT_PAGE_PARAM_KEY}
                    />
                  </div>
                )}
              </PaginatedTableContainer>
            ) : (
              <PlaidAccountsTableEmpty
                body={
                  <div className="mb-2 flex flex-col items-center">
                    <PlaidAccountsTableEmpty.AvatarGroup
                      variant={
                        accountType
                          ? variants(accountType, {
                              Deposit: "banks" as const,
                              Credit: "cards" as const,
                              Investment: "banks" as const,
                              Loan: "banks" as const,
                            })
                          : "banks"
                      }
                    />
                    <Heading4 className="mt-6 text-sm font-medium text-grey-800">
                      Connect your accounts and cards
                    </Heading4>
                    <Paragraph className="mt-2 max-w-md text-sm text-grey-800">
                      Connect your accounts to view a combined transaction feed with all your
                      transactions cleaned by Highbeam Intelligence.
                    </Paragraph>
                  </div>
                }
                footer={
                  // TODO(alex): HB-7034 Open the plaid modal here instead of navigating to the settings page.
                  <ButtonLink
                    to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
                  >
                    <PlaidAccountsTableEmpty.ButtonContent />
                  </ButtonLink>
                }
              />
            )}
          </>
        )}
      </div>
    </SectionV2>
  );
};

export { PLAID_ACCOUNT_PARAM_KEY, ENRICHED_ACCOUNT_PARAM_KEY };

export default EnrichedTransactionsSection;
