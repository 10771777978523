import { ChatTeardropText, X } from "@phosphor-icons/react";
import AiChatExchangeSuggestedFollowUps from "modules/chat/components/AiChatExchangeSuggestedFollowUps";
import AiChatFooter from "modules/chat/components/AiChatFooter";
import { AiChatUserAvatarByChatUserId } from "modules/chat/components/AiChatUserAvatar";
import useCreateChatChannelExchangeMutation from "modules/chat/mutations/useCreateChatChannelExchangeMutation";
import { useChatChannelOrThrow } from "modules/chat/queries/useChatChannel";
import { useChatThreadExchanges } from "modules/chat/queries/useChatThreadExchanges";
import { useCurrentChatUserOrThrow } from "modules/chat/queries/useChatUsers";
import { SuggestedFollowUp } from "modules/chat/reps/ExchangeRep";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import ChatElement from "pages/ai-chat/ChatElement";
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import ChatExchange from "ui/chat/ChatExchange";
import ChatExchangeForm, {
  ChatExchangeFormOutputs,
  useChatExchangeForm,
} from "ui/chat/ChatExchangeForm";
import ChatViewport from "ui/chat/ChatViewport";
import SidePanel, { useSidePanelContext } from "ui/data-display/SidePanel";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Heading1 } from "ui/typography";
import { copyToClipboard } from "utils/browser/useCopyToClipboard";
import cn from "utils/tailwind/cn";

import AiChatSuperuserDebugInfo from "./AiChatSuperuserDebugInfo";
import getIsAtBottom from "./getIsAtBottom";
import useUnreadExchangesCount from "./useUnreadExchangesCount";

// NB(alex): Temporary hack while I figure out a better solution.
// Fixes an issue where the sidepane's chart expands but doesn't shrink.
const WidthContainer: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useSidePanelContext();
  const hackHardCodedOffset = 32 + 32 + 16 + 32; // padding left + avatar + gap + padding right
  return (
    <div className="w-full" style={{ maxWidth: width - hackHardCodedOffset }}>
      {children}
    </div>
  );
};

const pxClasses = "px-4 @md:px-6 @lg:px-8";

type Props = {
  channelId: string;
  exchangeId: string;
};

const ChatExchangeSidePanelContent: FC<Props> = ({ channelId, exchangeId }) => {
  const suggestedFollowUpsFeatureFlag = useFeatureFlag("ai-chat-suggested-follow-ups");

  const exchanges = useChatThreadExchanges({
    channelId,
    parentExchangeId: exchangeId,
  });

  // Disable the form if superusering into another chat user's channel.
  const channel = useChatChannelOrThrow({ channelId });
  const currentChatUser = useCurrentChatUserOrThrow();
  const isSuperusering = useIsSuperusering();
  const isInOwnChannel = channel.chatUserIds.includes(currentChatUser.id);
  const isSuperuseringInOtherUserChannel = isSuperusering && !isInOwnChannel;

  const form = useChatExchangeForm({
    disabled: isSuperuseringInOtherUserChannel,
  });

  const { mutateAsync: createChatChannelExchange, isPending } =
    useCreateChatChannelExchangeMutation({
      onSuccess: (_data) => {
        form.reset();
      },
    });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current?.scrollHeight,
      behavior: "instant",
    });
  }, []);

  const { unreadExchangesCount, resetUnreadExchangesCount } = useUnreadExchangesCount({
    exchanges,
    scrollContainerElement: scrollContainerRef.current,
  });

  // Scroll to the most recent exchange and focus the textarea.
  useEffect(() => {
    scrollToBottom();
    textAreaRef.current?.focus();
  }, [scrollToBottom, exchangeId]);

  // Scroll to the most recent exchange when a new exchange comes in if scrolled is already at the bottom.
  useEffect(() => {
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [exchanges, scrollToBottom]);

  // Scroll to the most recent exchange when a new exchange comes in if scrolled is already at the bottom.
  useEffect(() => {
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [exchanges, scrollToBottom]);

  const onSubmit = useCallback(
    async (data: ChatExchangeFormOutputs) => {
      if (isPending) return;

      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelExchange({
        type: "UserPlaintext",
        channelId: channelId,
        parentId: exchangeId,
        content: data.content,
      });
    },
    [channelId, createChatChannelExchange, exchangeId, isPending, isSuperuseringInOtherUserChannel]
  );

  const handleSuggestedFollowUpClick = useCallback(
    async ({ content }: SuggestedFollowUp) => {
      if (isPending) return;

      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelExchange({
        type: "UserPlaintext",
        channelId: channelId,
        parentId: exchangeId,
        content,
      });
    },
    [channelId, createChatChannelExchange, exchangeId, isPending, isSuperuseringInOtherUserChannel]
  );

  return (
    <div className="flex h-full flex-col @container">
      <div
        className={cn(
          "flex items-center justify-between border-b border-b-grey-100 py-5",
          pxClasses
        )}
      >
        <div className="flex h-8 items-center gap-x-3">
          <ChatTeardropText size={24} className="-scale-x-100 text-grey-800" weight="light" />
          <Heading1 className="text-md font-bold text-grey-800">Thread</Heading1>
        </div>

        <SidePanel.Trigger>
          {({ onOpenToggle }) => (
            <Button
              variant="ghost"
              paddingVariant="square"
              size="sm"
              onClick={() => onOpenToggle()}
            >
              <X size={16} />
            </Button>
          )}
        </SidePanel.Trigger>
      </div>

      <ChatViewport>
        <ChatViewport.ScrollContainer
          className="overflow-x-auto py-7"
          ref={scrollContainerRef}
          onScroll={(e) => {
            if (unreadExchangesCount) {
              const isAtBottom = getIsAtBottom(e.target as HTMLDivElement);
              if (isAtBottom) {
                resetUnreadExchangesCount();
              }
            }
          }}
        >
          <div className={cn("pb-4 @container")}>
            {[...exchanges].reverse().map((exchange, exchangeIndex) => {
              const userMessage = exchange.userMessage;
              const userElements = userMessage ? userMessage.elements : [];
              const assistantMessage = exchange.assistantMessage;
              const assistantElements = assistantMessage
                ? assistantMessage.options[0].elements
                : [];
              const loadingMessages = exchange.loadingMessages.map((msg) => msg + "...");

              return (
                <div key={exchange.id}>
                  {userMessage && (
                    <div className="hover:bg-grey-50/50">
                      {userElements.map((userElement, userElementIndex) => (
                        <ChatExchange
                          key={userElementIndex} // Index is static.
                        >
                          <ChatExchange.Block
                            avatar={
                              <AiChatUserAvatarByChatUserId chatUserId={userMessage.senderId} />
                            }
                            className={pxClasses}
                          >
                            <WidthContainer>
                              <ChatElement element={userElement.populated} />
                            </WidthContainer>
                          </ChatExchange.Block>
                        </ChatExchange>
                      ))}
                    </div>
                  )}

                  <div className="hover:bg-grey-50/50">
                    {exchange.isLoading && (
                      <ChatExchange.BlockLoading
                        className={cn(pxClasses, "py-3 hover:bg-grey-50/50")}
                        messages={loadingMessages}
                      />
                    )}

                    {assistantElements.map((assistantElement, assistantElementIndex) => {
                      const isLastContentBlock =
                        assistantElementIndex === assistantElements.length - 1;
                      const showAiChatSuperuserDebugInfo = isSuperusering && isLastContentBlock;
                      const showSuggestedFollowUps =
                        suggestedFollowUpsFeatureFlag &&
                        exchangeIndex === exchanges.length - 1 &&
                        isLastContentBlock &&
                        exchange.suggestedFollowUps &&
                        exchange.suggestedFollowUps.length > 0;

                      return (
                        <ChatExchange
                          key={assistantElementIndex} // Index is static.
                        >
                          <ChatExchange.Block
                            key={assistantElementIndex} // Index is static.
                            avatar={
                              assistantElementIndex === 0 ? (
                                <AiChatUserAvatarByChatUserId
                                  chatUserId={assistantMessage!.senderId}
                                />
                              ) : (
                                <ChatExchange.NoAvatar />
                              )
                            }
                            className={pxClasses}
                          >
                            <WidthContainer>
                              <ChatElement element={assistantElement.populated} />
                            </WidthContainer>
                          </ChatExchange.Block>

                          {showAiChatSuperuserDebugInfo && (
                            <AiChatSuperuserDebugInfo exchangeId={exchange.id}>
                              <ChatExchange.Block className={pxClasses}>
                                <AiChatSuperuserDebugInfo.Button />
                                <Button
                                  className="text-purple-300"
                                  size="xs"
                                  onClick={() => {
                                    copyToClipboard(exchange.id);
                                    notify("success", `Copied ${exchange.id}`);
                                  }}
                                >
                                  [SU] Copy {exchange.id}
                                </Button>
                              </ChatExchange.Block>
                              <AiChatSuperuserDebugInfo.Content />
                            </AiChatSuperuserDebugInfo>
                          )}

                          {showSuggestedFollowUps && (
                            <ChatExchange.Block className={pxClasses}>
                              <AiChatExchangeSuggestedFollowUps>
                                {exchange.suggestedFollowUps?.map((suggestedFollowUp) => (
                                  <AiChatExchangeSuggestedFollowUps.Button
                                    key={suggestedFollowUp.display}
                                    suggestedFollowUp={suggestedFollowUp}
                                    isLoading={isPending}
                                    onClick={() => handleSuggestedFollowUpClick(suggestedFollowUp)}
                                  />
                                )) ?? <AiChatExchangeSuggestedFollowUps.Loading />}
                              </AiChatExchangeSuggestedFollowUps>
                            </ChatExchange.Block>
                          )}
                        </ChatExchange>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </ChatViewport.ScrollContainer>

        <ChatViewport.InputContainer className={pxClasses}>
          {Boolean(unreadExchangesCount) && (
            <ChatViewport.NewExchangeButton
              unreadExchangesCount={unreadExchangesCount}
              onClick={() => {
                scrollToBottom();
              }}
            />
          )}

          <ChatExchangeForm onSubmit={onSubmit} form={form}>
            <ChatExchangeForm.TextArea textAreaRef={textAreaRef} />
            <ChatExchangeForm.SubmitButton />
          </ChatExchangeForm>

          <AiChatFooter className="px-2 py-5">
            <AiChatFooter.Disclaimer />
          </AiChatFooter>
        </ChatViewport.InputContainer>
      </ChatViewport>
    </div>
  );
};

export default ChatExchangeSidePanelContent;
