import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import InternationalPayeeBankingInfo from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeBankingInfo";
import InternationalPayeeEntity from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeEntity";
import InternationalPayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeLegalAddress";
import PaymentCurrency from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PaymentCurrency";
import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import CollapsibleFieldset from "ui/inputs/CollapsibleFieldset";
import { Heading4 } from "ui/typography";

import styles from "./CreatePayeePage.module.scss";

const InternationalWireSection = () => {
  const isBusinessActive = useIsBusinessActive();
  const selectedBankCountry = useRecoilValue(selectedBankCountryOptionState);
  const heading = <Heading4>International Wire</Heading4>;

  const resetAddress = useResetRecoilState(internationalAddressState);
  const resetInternationalBankingInfo = useResetRecoilState(internationalBankingInfoState);
  const resetBankCountry = useResetRecoilState(selectedBankCountryOptionState);

  const resetInternationalWireInfo = () => {
    resetBankCountry();
    resetAddress();
    resetInternationalBankingInfo();
  };

  // Cleanup if user navigates away from page
  useEffect(
    () => () => {
      resetInternationalWireInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <CollapsibleFieldset
      heading={heading}
      disabled={!isBusinessActive}
      disabledTooltipContent="Verify your business to add international payment info."
    >
      <div className={styles.span2}>
        <PaymentCurrency />
      </div>
      <div className={styles.span2}>
        <InternationalPayeeEntity />
      </div>
      <div className={styles.span2}>
        <InternationalPayeeBankingInfo />
      </div>
      <div className={styles.span2}>
        {selectedBankCountry && <InternationalPayeeLegalAddress />}
      </div>
    </CollapsibleFieldset>
  );
};

export default InternationalWireSection;
