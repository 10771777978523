import { PlusCircle } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { intelligenceStatusQueryHooks } from "modules/insights/queries/useIntelligenceStatus";
import { NormalizedAccountAugmented } from "modules/insights/utils/augment-normalized-account";
import { SETTINGS_PLAID_LINK_MODAL_KEY } from "modules/plaid/components/PlaidConnectionsList";
import { FC, useMemo, useState, useEffect } from "react";
import ButtonLink from "ui/inputs/ButtonLink";
import { isNotNull } from "utils/array";
import { roundEpsilon } from "utils/math";

import NormalizedAccountAugmentedBar from "../NormalizedAccountRepBar/NormalizedAccountAugmentedBar";

import CleaningTransactions from "./CleaningTransactions";

type UseNormalizedAccountsBeingCleanedParams = {
  normalizedAccountsAugmented: NormalizedAccountAugmented[];
  onFinishedCleaning: () => void;
};

const useNormalizedAccountsBeingCleaned = ({
  normalizedAccountsAugmented,
  onFinishedCleaning,
}: UseNormalizedAccountsBeingCleanedParams) => {
  const [pollingInterval, setPollingInterval] = useState<number | null>(2000);

  const { data: financialAccountsBeingCleaned, isLoading: isLoadingFinancialAccountsBeingCleaned } =
    intelligenceStatusQueryHooks.useQuery({
      params: {},
      select: (data) => {
        return data.financialAccounts.filter((fa) => {
          const allTransactionsEnriched = fa.totalEnrichedTransactions === fa.totalTransactions;
          // Only show accounts that aren't fully cleaned or if they were created within the last hour.
          const accountCreatedWithinLastHour = dayjs(fa.normalizedAccountCreatedAt).isAfter(
            dayjs().subtract(1, "hour")
          );

          return !allTransactionsEnriched || accountCreatedWithinLastHour;
        });
      },
      enabled: Boolean(pollingInterval),
      refetchInterval: pollingInterval ?? false,
    });

  const normalizedAccountsBeingCleaned = useMemo(() => {
    if (!financialAccountsBeingCleaned) {
      return [];
    }

    const results = normalizedAccountsAugmented
      .map((na) => {
        const matchingFinancialAccount = financialAccountsBeingCleaned.find(
          (fa) => fa.normalizedAccountId === na.id
        );
        if (!matchingFinancialAccount) return null;
        return {
          ...na,
          ...matchingFinancialAccount,
        };
      })
      .filter(isNotNull);

    return results;
  }, [financialAccountsBeingCleaned, normalizedAccountsAugmented]);

  // Disable query/polling if no results are listed or if all are finished.
  useEffect(() => {
    const allFinished =
      normalizedAccountsBeingCleaned.length > 0 &&
      normalizedAccountsBeingCleaned.every((na) => {
        const wasCreatedWithinLastHour = dayjs(na.normalizedAccountCreatedAt).isAfter(
          dayjs().subtract(1, "hour")
        );
        const isFullyCleaned = na.totalEnrichedTransactions === na.totalTransactions;

        // If older than an hour, we bypass accounts that have 0 transactions.
        if (wasCreatedWithinLastHour) {
          return isFullyCleaned && na.totalTransactions !== 0;
        } else {
          return isFullyCleaned;
        }
      });

    if (
      (!isLoadingFinancialAccountsBeingCleaned && normalizedAccountsBeingCleaned.length === 0) ||
      allFinished
    ) {
      setPollingInterval(null);
      if (financialAccountsBeingCleaned && financialAccountsBeingCleaned.length > 0) {
        onFinishedCleaning();
      }
    }
  }, [
    normalizedAccountsBeingCleaned,
    onFinishedCleaning,
    financialAccountsBeingCleaned,
    isLoadingFinancialAccountsBeingCleaned,
  ]);

  return normalizedAccountsBeingCleaned;
};

type Props = {
  normalizedAccountsBeingCleaned: ReturnType<typeof useNormalizedAccountsBeingCleaned>;
  className?: string;
};

const CleaningTransactionsImpl: FC<Props> = ({ normalizedAccountsBeingCleaned, className }) => {
  return (
    <CleaningTransactions className={className}>
      <CleaningTransactions.Header>
        <CleaningTransactions.Heading />
        <div className="flex items-center">
          <ButtonLink
            to={`/settings/banks-and-cards?${SETTINGS_PLAID_LINK_MODAL_KEY}=true`}
            className="-mr-3"
          >
            <PlusCircle />{" "}
            <span>
              Add<span className="hidden @xs:inline"> connection</span>
            </span>
          </ButtonLink>
        </div>
      </CleaningTransactions.Header>
      {normalizedAccountsBeingCleaned.map((na) => {
        const wasCreatedWithinLastHour = dayjs(na.normalizedAccountCreatedAt).isAfter(
          dayjs().subtract(1, "hour")
        );

        return (
          <CleaningTransactions.Row key={na.id}>
            <NormalizedAccountAugmentedBar normalizedAccountAugmented={na} />

            <CleaningTransactions.Completion
              completionPercentage={
                na.totalTransactions === 0
                  ? wasCreatedWithinLastHour
                    ? 0
                    : 100
                  : roundEpsilon((na.totalEnrichedTransactions / na.totalTransactions) * 100, 0)
              }
            />
          </CleaningTransactions.Row>
        );
      })}
    </CleaningTransactions>
  );
};

export { useNormalizedAccountsBeingCleaned };

export default CleaningTransactionsImpl;
