import { FileSearch, Sparkle, Table } from "@phosphor-icons/react";
import { FC } from "react";
import UnorderedList from "ui/typography/UnorderedList";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const ConnectYourAccountsYouWillGet: FC<Props> = ({ className }) => {
  return (
    <div className={cn("text-sm", className)}>
      <div className="flex items-center gap-x-2 font-bold text-inherit">You will get</div>
      <ul className="mt-5 list-none text-inherit">
        <li>
          <UnorderedList.Content className="text-inherit">
            <FileSearch size={20} weight="light" /> Access to Highbeam AI Analyst
          </UnorderedList.Content>
        </li>
        <li className="mt-4">
          <UnorderedList.Content className="text-inherit">
            <Table size={20} weight="light" /> A live Google sheet with your 13-week cash forecast
          </UnorderedList.Content>
        </li>
        <li className="mt-4">
          <UnorderedList.Content className="text-inherit">
            <Sparkle size={20} weight="light" />
            Cleaned, categorized transactions across all your accounts
          </UnorderedList.Content>
        </li>
      </ul>
    </div>
  );
};

export default ConnectYourAccountsYouWillGet;
