import { bankAccountsQueryHooks } from "modules/bank-accounts/queries/useBankAccounts";
import { plaidAccountsQueryHooks } from "modules/plaid/queries/usePlaidAccounts";
import { isNotNull } from "utils/array";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import { SearchNormalizedAccountQueryParams } from "../api/useNormalizedAccountApi";
import { AccountType } from "../reps/AccountType";
import {
  augmentNormalizedAccount,
  NormalizedAccountAugmented,
} from "../utils/augment-normalized-account";

import {
  normalizedAccountsQueryHooks,
  ROOT_NORMALIZED_ACCOUNTS_QUERY_KEY,
} from "./useNormalizedAccounts";

const normalizedAccountsAugmentedQueryHooks = makeQueryHooksV2({
  useQueryFnParams: () => {
    const ensureBankAccounts = bankAccountsQueryHooks.useEnsureQueryData();
    const ensurePlaidAccounts = plaidAccountsQueryHooks.useEnsureQueryData();
    const ensureNormalizedAccounts = normalizedAccountsQueryHooks.useEnsureQueryData();
    return {
      ensureBankAccounts,
      ensurePlaidAccounts,
      ensureNormalizedAccounts,
    };
  },
  makeQueryKey: (params: SearchNormalizedAccountQueryParams) => {
    return [ROOT_NORMALIZED_ACCOUNTS_QUERY_KEY, "normalizedAccountsAugmented", params];
  },
  makeQueryFn: ({ ensureBankAccounts, ensurePlaidAccounts, ensureNormalizedAccounts }, params) => {
    return async () => {
      const bankAccounts = await ensureBankAccounts({});
      const plaidBankAccounts = await ensurePlaidAccounts({});
      const normalizedAccounts = await ensureNormalizedAccounts(params);

      return normalizedAccounts
        .map((normalizedAccount) => {
          return augmentNormalizedAccount({
            normalizedAccount,
            bankAccounts,
            connectedPlaidBankAccounts: plaidBankAccounts,
          });
        })
        .filter(isNotNull)
        .sort((a, b) => {
          if (a.institutionName === "Highbeam" && b.institutionName === "Highbeam") {
            if (a.subtype === "High Yield" && b.subtype !== "High Yield") return 1;
            if (a.subtype !== "High Yield" && b.subtype === "High Yield") return -1;
          }
          if (a.institutionName === "Highbeam" && b.institutionName !== "Highbeam") return -1;
          if (b.institutionName === "Highbeam" && a.institutionName !== "Highbeam") return 1;
          return a.accountName.localeCompare(b.accountName);
        });
    };
  },
});

type FilterNormalizedAccountsAugmentedParams = {
  accountType?: AccountType;
  includeHighbeam?: boolean;
};

const filterNormalizedAccountsAugmented = (
  normalizedAccountsAugmented: NormalizedAccountAugmented[],
  filters: FilterNormalizedAccountsAugmentedParams
) => {
  const { accountType, includeHighbeam } = filters;
  // eslint-disable-next-line functional/no-let
  let result = normalizedAccountsAugmented;
  if (accountType) {
    result = normalizedAccountsAugmented.filter(
      (normalizedAccount) => normalizedAccount.type === accountType
    );
  }
  if (!includeHighbeam) {
    result = result.filter((normalizedAccount) => normalizedAccount.institutionName !== "Highbeam");
  }
  return result;
};

type UseNormalizedAccountsAugmentedParams = {
  params?: SearchNormalizedAccountQueryParams;
  filters?: FilterNormalizedAccountsAugmentedParams;
};

const useNormalizedAccountsAugmented = (params?: UseNormalizedAccountsAugmentedParams) => {
  return normalizedAccountsAugmentedQueryHooks.useData({
    params: params?.params ?? {},
    select: (normalizedAccountsAugmented) => {
      return filterNormalizedAccountsAugmented(normalizedAccountsAugmented, params?.filters ?? {});
    },
  });
};

const useNormalizedAccountsAugmentedQuery = (params?: UseNormalizedAccountsAugmentedParams) => {
  return normalizedAccountsAugmentedQueryHooks.useQuery({
    params: params?.params ?? {},
    select: (normalizedAccountsAugmented) => {
      return filterNormalizedAccountsAugmented(normalizedAccountsAugmented, params?.filters ?? {});
    },
  });
};

export {
  normalizedAccountsAugmentedQueryHooks,
  filterNormalizedAccountsAugmented,
  useNormalizedAccountsAugmented,
  useNormalizedAccountsAugmentedQuery,
};
