import {
  filterNormalizedAccountsAugmented,
  normalizedAccountsAugmentedQueryHooks,
} from "modules/insights/queries/useNormalizedAccountsAugmented";
import PlaidInstitutionLogo from "modules/plaid/components/PlaidInstitutionLogo";
import { FC } from "react";
import { HighbeamLogo } from "ui/icons/logos/logos-square";
import PlatformAvatar from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import TabsV2 from "ui/navigation/TabsV2";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";

// NB(alex): Copied mostly over from `AccountsTransactionsPageTabs`.

const CARDS_TAB_PARAM_KEY = "show";

type Tabs = "highbeam-cards" | "connected-cards";

const useCardsTransactionsPageTabs = (defaultTab?: Tabs) => {
  const normalizedAccountsAugmented = normalizedAccountsAugmentedQueryHooks.useData({
    params: {},
    select: (normalizedAccountsAugmented) => {
      const uniqueInstitutions = normalizedAccountsAugmented.filter(
        (account, index, self) =>
          index === self.findIndex((c) => c.institutionId === account.institutionId)
      );
      return filterNormalizedAccountsAugmented(uniqueInstitutions, {
        accountType: "Credit",
        includeHighbeam: false,
      });
    },
  });

  const tabs: TabConfig = {
    "highbeam-cards": {
      icon: (
        <PlatformAvatar variant="small-grey-circle" className="shrink-0">
          <HighbeamLogo />
        </PlatformAvatar>
      ),
      label: "Highbeam cards",
    },
    "connected-cards": {
      icon: (
        <PlatformAvatarGroup variant="small-grey-circle">
          {normalizedAccountsAugmented.map(({ institutionId }) => {
            return (
              <PlatformAvatar variant="small-grey-circle" key={institutionId}>
                <PlaidInstitutionLogo institutionId={institutionId} />
              </PlatformAvatar>
            );
          })}
        </PlatformAvatarGroup>
      ),
      label: "Connected cards",
    },
  };

  const [activeTab, setActiveTab] = useSearchParamTabState(CARDS_TAB_PARAM_KEY, tabs, defaultTab);

  return { tabs, activeTab, setActiveTab };
};

type Props = ReturnType<typeof useCardsTransactionsPageTabs> & {
  className?: string;
};

const CardsTransactionsPageTabs: FC<Props> = ({ className, ...props }) => {
  return <TabsV2 {...props} className={className} />;
};

export { useCardsTransactionsPageTabs, CARDS_TAB_PARAM_KEY };

export default CardsTransactionsPageTabs;
