import { ErrorBoundary } from "@sentry/react";
import dayjs from "dayjs";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import AiChatExchangeFooter from "modules/chat/components/AiChatExchangeFooter";
import AiChatExchangeSuggestedFollowUps from "modules/chat/components/AiChatExchangeSuggestedFollowUps";
import AiChatFooter from "modules/chat/components/AiChatFooter";
import { AiChatUserAvatarByChatUserId } from "modules/chat/components/AiChatUserAvatar";
import DataSourcesModal from "modules/chat/components/DataSourcesModal/DataSourcesModal";
import DataSourcesPlatformAvatarGroup from "modules/chat/components/DataSourcesPlatformAvatarGroup/DataSourcesPlatformAvatarGroup";
import useCreateChatChannelExchangeMutation from "modules/chat/mutations/useCreateChatChannelExchangeMutation";
import { useChatChannel } from "modules/chat/queries/useChatChannel";
import { useChatChannelExchanges } from "modules/chat/queries/useChatChannelExchanges";
import { useCurrentChatUser } from "modules/chat/queries/useChatUsers";
import { DataSources, useDataSources } from "modules/chat/queries/useDataSources";
import { useRandomSuggestedQuestions } from "modules/chat/queries/useSuggestedQuestions";
import { ChannelRep } from "modules/chat/reps/ChannelRep";
import { ChatUserRep } from "modules/chat/reps/ChatUserRep";
import { anySource, SuggestedFollowUp } from "modules/chat/reps/ExchangeRep";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import ChatExchangeSidePanelContent from "pages/ai-chat/AiChatChannelPage/ChatExchangeSidePanelContent";
import useUnreadExchangesCount from "pages/ai-chat/AiChatChannelPage/useUnreadExchangesCount";
import ChatElement from "pages/ai-chat/ChatElement";
import {
  ComponentProps,
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Navigate, useParams } from "react-router-dom";
import { useIsSuperusering } from "state/auth/isSuperusering";
import ChatExchange from "ui/chat/ChatExchange";
import ChatExchangeForm, {
  ChatExchangeFormOutputs,
  useChatExchangeForm,
} from "ui/chat/ChatExchangeForm";
import ChatViewport from "ui/chat/ChatViewport";
import SidePanel from "ui/data-display/SidePanel";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import VirtualButton from "ui/inputs/VirtualButton";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import Tooltip from "ui/overlay/Tooltip";
import { copyToClipboard } from "utils/browser/useCopyToClipboard";
import useMountEffect from "utils/customHooks/useMountEffect";
import useElementSize from "utils/device/useElementSize";
import useModalV2 from "utils/dialog/useModalV2";
import cn from "utils/tailwind/cn";

import AiChatChannelPageChatViewport from "./AiChatChannelPageChatViewport";
import AiChatChannelPageDeleteExchangeButton from "./AiChatChannelPageDeleteExchangeButton";
import AiChatSuperuserDebugInfo from "./AiChatSuperuserDebugInfo";
import ChatSuggestionsEmbeddedDrawer from "./ChatSuggestionsEmbeddedDrawer";
import getIsAtBottom from "./getIsAtBottom";
import useChatPageKeyboardEvents from "./useChatPageKeyboardEvents";

const pxClasses = "px-4 @md:px-6 @lg:px-8";
const normalWidthClasses = "mx-auto w-full max-w-3xl";
const widerWidthClasses = "mx-auto w-full max-w-7xl";

type AiChatChannelPageContentProps = {
  chatChannel: ChannelRep;
  currentChatUser: ChatUserRep;
};

const AiChatChannelPageContent: FC<AiChatChannelPageContentProps> = ({
  chatChannel,
  currentChatUser,
}) => {
  const suggestedFollowUpsFeatureFlag = useFeatureFlag("ai-chat-suggested-follow-ups");

  const { width: containerWidth = 0, elementRef: containerRef } = useElementSize();

  const chatChannelExchanges = useChatChannelExchanges({
    channelId: chatChannel.id,
    // TODO(alex): HB-6780 Implement pagination.
    latestExclusive: null,
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { elementRef: textAreaActionsContainerRef, width: textAreaActionsContainerWidth } =
    useElementSize();

  const scrollToBottom = useCallback(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current?.scrollHeight,
      behavior: "instant",
    });
  }, []);

  const { unreadExchangesCount, resetUnreadExchangesCount } = useUnreadExchangesCount({
    exchanges: chatChannelExchanges,
    scrollContainerElement: scrollContainerRef.current,
  });

  // Scroll to the most recent exchange on mount.
  useMountEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    // Scroll to the most recent exchange when a new exchange comes in if scrolled is already at the bottom.
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [chatChannelExchanges, scrollToBottom]);

  // Disable the form if superusering into another chat user's channel.
  const isSuperusering = useIsSuperusering();
  const isInOwnChannel = chatChannel.chatUserIds.includes(currentChatUser.id);
  const isSuperuseringInOtherUserChannel = isSuperusering && !isInOwnChannel;
  const showClearChannelButton = isSuperusering && isInOwnChannel;

  const form = useChatExchangeForm({
    disabled: isSuperuseringInOtherUserChannel,
  });

  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(
    chatChannelExchanges.filter((exchange) => exchange.userMessage !== null).length === 0
  );

  const { mutateAsync: createChatChannelExchange, isPending } =
    useCreateChatChannelExchangeMutation({
      onMutate: () => {
        isSuggestionsOpen && setIsSuggestionsOpen(false);
      },
      onSuccess: (_data) => {
        form.reset();
      },
    });

  const onSubmit = useCallback(
    async (data: ChatExchangeFormOutputs) => {
      if (isPending) return;
      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelExchange({
        type: "UserPlaintext",
        channelId: chatChannel.id,
        parentId: null,
        content: data.content,
      });
    },
    [chatChannel.id, isPending, createChatChannelExchange, isSuperuseringInOtherUserChannel]
  );

  const handleSuggestedFollowUpClick = useCallback(
    async (exchangeId: string, { content, useThread }: SuggestedFollowUp) => {
      if (isPending) return;
      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelExchange({
        type: "UserPlaintext",
        channelId: chatChannel.id,
        parentId: useThread ? exchangeId : null,
        content,
      });
    },
    [isPending, isSuperuseringInOtherUserChannel, createChatChannelExchange, chatChannel.id]
  );

  useChatPageKeyboardEvents({
    drawerContainerRef,
    textAreaRef,
  });

  // TODO(alex): HB-6803 Update to use query param.
  const [sidePanelContentState, setSidePanelContentState] = useState<ComponentProps<
    typeof ChatExchangeSidePanelContent
  > | null>(null);

  const suggestedQuestions = useRandomSuggestedQuestions({ useCase: "ai-chat", limit: 6 });
  const showSuggestedQuestions = suggestedQuestions.length > 0;

  const reversedChatChannelExchanges = useMemo(
    () => [...chatChannelExchanges].reverse(),
    [chatChannelExchanges]
  );

  const dataSources = useDataSources();
  const dataSourcesModal = useModalV2<{ dataSources: DataSources }>();

  return (
    <SidePanel containerWidth={containerWidth}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>{chatChannel.name}</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {dataSourcesModal.isOpen && (
        <DataSourcesModal {...dataSourcesModal.params} onClose={dataSourcesModal.close} />
      )}

      <div className="flex h-full w-full @container" ref={containerRef}>
        <div className="flex flex-1 flex-col">
          <AiChatChannelPageChatViewport>
            <ChatViewport.ScrollContainer
              className="relative"
              ref={scrollContainerRef}
              onScroll={(e) => {
                if (unreadExchangesCount > 0) {
                  const isAtBottom = getIsAtBottom(e.target as HTMLDivElement);
                  if (isAtBottom) {
                    resetUnreadExchangesCount();
                  }
                }
              }}
            >
              <div className="mx-auto pb-16 pt-7 @container">
                {reversedChatChannelExchanges.map((exchange, exchangeIndex) => {
                  const userMessage = exchange.userMessage;
                  const userElements = userMessage ? userMessage.elements : [];
                  const assistantMessage = exchange.assistantMessage;
                  const assistantElements = assistantMessage
                    ? assistantMessage.options[0].elements
                    : [];

                  const loadingMessages =
                    exchange.loadingMessages.length > 0
                      ? exchange.loadingMessages.map((msg) => msg + "...")
                      : ["Loading..."];
                  const createdAt = dayjs(exchange.createdAt);

                  return (
                    <div key={exchange.id}>
                      {userMessage && (
                        <div className="group/chat-message hover:bg-grey-50/50">
                          {userElements.map((userElement, userElementIndex) => (
                            <ChatExchange
                              key={userElementIndex} // Index is static.
                            >
                              <ChatExchange.Block
                                avatar={
                                  <div className="relative h-fit">
                                    <Tooltip>
                                      <Tooltip.Trigger asChild>
                                        <span
                                          className={cn(
                                            "invisible absolute -left-6 top-4 -translate-x-full -translate-y-1/2 whitespace-nowrap text-grey-500 hover:underline focus:outline-focus group-hover/chat-message:visible",
                                            "hidden @4xl:block" // Hack breakpoint - hoping to have a mobile version of this soon.
                                          )}
                                          tabIndex={0}
                                        >
                                          {createdAt.format("h:mm A")}
                                        </span>
                                      </Tooltip.Trigger>
                                      <Tooltip.Content>
                                        {createdAt.format("MMM D [at] h:mm A z")}
                                      </Tooltip.Content>
                                    </Tooltip>
                                    <AiChatUserAvatarByChatUserId
                                      chatUserId={userMessage.senderId}
                                    />
                                  </div>
                                }
                                className={cn(normalWidthClasses, pxClasses, "relative")}
                              >
                                <ChatElement element={userElement.populated} />
                              </ChatExchange.Block>
                            </ChatExchange>
                          ))}
                        </div>
                      )}

                      <div className="group/chat-message hover:bg-grey-50/50">
                        {exchange.isLoading && (
                          <ChatExchange.BlockLoading
                            className={cn(normalWidthClasses, pxClasses, "py-3")}
                            messages={loadingMessages}
                          />
                        )}

                        {assistantElements.map((assistantElement, assistantElementIndex) => {
                          const isLastContentBlock =
                            assistantElementIndex === assistantElements.length - 1;
                          const showReplyInThreadButton =
                            exchange.allowThreads && isLastContentBlock && !exchange.isLoading;
                          const showExchangeFooter = isLastContentBlock && !exchange.isLoading;
                          const showAiChatSuperuserDebugInfo = isSuperusering && isLastContentBlock;
                          const showSuggestedFollowUps =
                            suggestedFollowUpsFeatureFlag &&
                            exchangeIndex === reversedChatChannelExchanges.length - 1 &&
                            isLastContentBlock &&
                            exchange.suggestedFollowUps &&
                            exchange.suggestedFollowUps.length > 0 &&
                            exchange.replyCount === 0;

                          return (
                            <ChatExchange
                              key={assistantElementIndex} // Index is static.
                            >
                              <ChatExchange.Block
                                key={assistantElementIndex} // Index is static.
                                avatar={
                                  <div className="relative h-fit">
                                    {assistantElementIndex === 0 ? (
                                      <>
                                        <Tooltip>
                                          <Tooltip.Trigger asChild>
                                            <span
                                              className={cn(
                                                "invisible absolute -left-6 top-4 -translate-x-full -translate-y-1/2 whitespace-nowrap text-grey-500 hover:underline focus:outline-focus group-hover/chat-message:visible",
                                                "hidden @4xl:block" // Hack breakpoint - hoping to have a mobile version of this soon.
                                              )}
                                              tabIndex={0}
                                            >
                                              {createdAt.format("h:mm A")}
                                            </span>
                                          </Tooltip.Trigger>
                                          <Tooltip.Content>
                                            {createdAt.format("MMM D [at] h:mm A z")}
                                          </Tooltip.Content>
                                        </Tooltip>

                                        <AiChatUserAvatarByChatUserId
                                          chatUserId={assistantMessage!.senderId}
                                        />
                                      </>
                                    ) : (
                                      <ChatExchange.NoAvatar />
                                    )}
                                  </div>
                                }
                                className={cn(normalWidthClasses, pxClasses, "relative")}
                              >
                                {/* NB(alex): Hack to fix page from crashing. Will address this properly later in HB-6662 */}
                                <ErrorBoundary fallback={<></>}>
                                  <div
                                    className={cn(
                                      "w-full",
                                      // NB(alex): Hack to get the table width correct. No clue how else to fix it :/ -- would be nice to re-visit this after launch.
                                      assistantElement.populated?.type === "Table" && "pr-12"
                                    )}
                                  >
                                    <ChatElement
                                      element={assistantElement.populated}
                                      key={assistantElement.populated?.type ?? "loading"} // NB(alex): Attempt at fixing https://linear.app/highbeam/issue/HB-7100/react-key-issues-with-incremental-content
                                    />
                                  </div>
                                </ErrorBoundary>
                              </ChatExchange.Block>

                              {showExchangeFooter && (
                                <ChatExchange.Block className={cn(normalWidthClasses, pxClasses)}>
                                  <AiChatExchangeFooter
                                    exchange={exchange}
                                    otherActions={
                                      showReplyInThreadButton && (
                                        <SidePanel.Trigger>
                                          {({ isOpen, onOpenToggle }) => (
                                            <ChatExchange.ReplyInThreadButton
                                              className={cn(
                                                "w-auto",
                                                // NB(lev): Cancel negative margin if there are no sources (in which case, the button is adjacent to the rating buttons).
                                                !anySource(exchange.sources) && "mx-0"
                                              )}
                                              size="xs"
                                              replyCount={exchange.replyCount}
                                              onClick={() => {
                                                // Set the panel's state to the current exchange if panel is being opened.
                                                setSidePanelContentState({
                                                  exchangeId: exchange.id,
                                                  channelId: chatChannel.id,
                                                });

                                                // Open the panel if it's closed.
                                                if (!isOpen) {
                                                  onOpenToggle();
                                                } else {
                                                  // Close the panel if clicking on this exchange's button and the panel is already open.
                                                  if (
                                                    sidePanelContentState?.exchangeId ===
                                                    exchange.id
                                                  ) {
                                                    onOpenToggle();
                                                  }
                                                }
                                              }}
                                            />
                                          )}
                                        </SidePanel.Trigger>
                                      )
                                    }
                                  />
                                </ChatExchange.Block>
                              )}

                              {showAiChatSuperuserDebugInfo && (
                                <AiChatSuperuserDebugInfo exchangeId={exchange.id}>
                                  <ChatExchange.Block className={cn(normalWidthClasses, pxClasses)}>
                                    <AiChatSuperuserDebugInfo.Button />
                                    <AiChatSuperuserDebugInfo.IfVisible>
                                      <Button
                                        className="text-purple-300"
                                        size="xs"
                                        onClick={() => {
                                          copyToClipboard(exchange.id);
                                          notify("success", `Copied ${exchange.id}`);
                                        }}
                                      >
                                        [SU] Copy {exchange.id}
                                      </Button>

                                      <AiChatChannelPageDeleteExchangeButton
                                        exchangeId={exchange.id}
                                      />
                                    </AiChatSuperuserDebugInfo.IfVisible>
                                  </ChatExchange.Block>
                                  <ChatExchange.Block className={cn(widerWidthClasses, pxClasses)}>
                                    <AiChatSuperuserDebugInfo.Content />
                                  </ChatExchange.Block>
                                </AiChatSuperuserDebugInfo>
                              )}

                              {showSuggestedFollowUps && (
                                <ChatExchange.Block className={cn(normalWidthClasses, pxClasses)}>
                                  <SidePanel.Trigger>
                                    {({ isOpen, onOpenToggle }) => (
                                      <AiChatExchangeSuggestedFollowUps>
                                        {exchange.suggestedFollowUps ? (
                                          exchange.suggestedFollowUps.map((suggestedFollowUp) => (
                                            <AiChatExchangeSuggestedFollowUps.Button
                                              key={suggestedFollowUp.display}
                                              suggestedFollowUp={suggestedFollowUp}
                                              isLoading={isPending}
                                              onClick={() => {
                                                handleSuggestedFollowUpClick(
                                                  exchange.id,
                                                  suggestedFollowUp
                                                );
                                                if (suggestedFollowUp.useThread) {
                                                  setSidePanelContentState({
                                                    exchangeId: exchange.id,
                                                    channelId: chatChannel.id,
                                                  });
                                                  if (!isOpen) onOpenToggle();
                                                } else {
                                                  if (isOpen) onOpenToggle();
                                                }
                                              }}
                                            />
                                          ))
                                        ) : (
                                          <AiChatExchangeSuggestedFollowUps.Loading />
                                        )}
                                      </AiChatExchangeSuggestedFollowUps>
                                    )}
                                  </SidePanel.Trigger>
                                </ChatExchange.Block>
                              )}
                            </ChatExchange>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </ChatViewport.ScrollContainer>

            <ChatViewport.InputContainer className="px-4 tablet:px-12" ref={inputContainerRef}>
              {Boolean(unreadExchangesCount) && (
                <ChatViewport.NewExchangeButton
                  unreadExchangesCount={unreadExchangesCount}
                  onClick={() => {
                    scrollToBottom();
                  }}
                />
              )}

              <div className={cn("relative", normalWidthClasses)}>
                {showSuggestedQuestions && (
                  <ChatSuggestionsEmbeddedDrawer
                    style={{
                      ...(textAreaRef.current
                        ? { bottom: textAreaRef.current?.clientHeight + 1 }
                        : { display: "none" }),
                    }}
                    className={cn(
                      "z-[1]",
                      "shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),_0px_1px_2px_0px_rgba(0,0,0,0.06)]",
                      "max-h-none",
                      "mx-px"
                    )}
                    isOpen={isSuggestionsOpen}
                    onOpen={() => setIsSuggestionsOpen(true)}
                    onClose={() => setIsSuggestionsOpen(false)}
                    ref={drawerContainerRef}
                  >
                    <ChatSuggestionsEmbeddedDrawer.Header
                      numSuggestions={suggestedQuestions.length}
                    />
                    <ChatSuggestionsEmbeddedDrawer.Body className="pb-3">
                      {suggestedQuestions.map((suggestedQuestion) => (
                        <ChatSuggestionsEmbeddedDrawer.SuggestionButton
                          key={suggestedQuestion.id}
                          markdown={suggestedQuestion.markdown}
                          onClick={() => {
                            onSubmit({ content: suggestedQuestion.plaintext });
                            setIsSuggestionsOpen(false);
                          }}
                        />
                      ))}
                    </ChatSuggestionsEmbeddedDrawer.Body>
                  </ChatSuggestionsEmbeddedDrawer>
                )}

                <ChatExchangeForm
                  className={cn("z-[2]", normalWidthClasses)}
                  onSubmit={onSubmit}
                  form={form}
                >
                  <ChatExchangeForm.TextArea
                    textAreaRef={textAreaRef}
                    inputStyle={{
                      paddingRight: textAreaActionsContainerWidth
                        ? textAreaActionsContainerWidth + 12
                        : 0,
                    }}
                  />

                  <div
                    className="absolute right-3 top-3 flex items-center gap-4"
                    ref={textAreaActionsContainerRef}
                  >
                    <ItemWithTooltip
                      tooltip="Data sources"
                      contentPaddingVariant="compact"
                      contentSideOffset={6}
                    >
                      <VirtualButton
                        onClick={() => {
                          dataSourcesModal.open({ dataSources });
                        }}
                      >
                        <DataSourcesPlatformAvatarGroup {...dataSources} interactive />
                      </VirtualButton>
                    </ItemWithTooltip>
                    <ChatExchangeForm.SubmitButton className="static" />
                  </div>
                </ChatExchangeForm>
              </div>

              <AiChatFooter className="mx-auto py-4">
                <AiChatFooter.Disclaimer />
                {showClearChannelButton && (
                  <AiChatFooter.ClearChannelButton channelId={chatChannel.id} />
                )}
              </AiChatFooter>
            </ChatViewport.InputContainer>
          </AiChatChannelPageChatViewport>
        </div>

        <SidePanel.Panel
          className="tablet:z-[3]" // Prevents textarea from appearing over the side panel, but on mobile, the low z-index causes the full drawer to disappear.
        >
          <Suspense fallback={null}>
            {sidePanelContentState && <ChatExchangeSidePanelContent {...sidePanelContentState} />}
          </Suspense>
        </SidePanel.Panel>
      </div>
    </SidePanel>
  );
};

const AiChatChannelPage = () => {
  const currentChatUser = useCurrentChatUser();
  const { channelId } = useParams();
  const chatChannel = useChatChannel({ channelId: channelId! });
  const businessGuid = useBusinessGuid();

  if (
    !chatChannel ||
    !currentChatUser ||
    // Prevents superusers from accidentally using other business chats when not superusering.
    chatChannel.businessGuid !== businessGuid
  ) {
    return <Navigate to="/ai" replace />;
  }

  return <AiChatChannelPageContent chatChannel={chatChannel} currentChatUser={currentChatUser} />;
};

export default AiChatChannelPage;
