import DashboardLayout from "layouts/Dashboard";
import { useHasSkippedOnboarding } from "modules/action-items/queries/useHasSkippedOnboarding";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import BusinessDisambiguator from "modules/business-disambiguator/BusinessDisambiguator";
import { useIsBusinessActive } from "modules/business/queries/useBusiness";
import useIsAllowedToNavigateToChatRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatRoutes";
import useMostRecentCreditApplication from "modules/credit-application/queries/useMostRecentCreditApplication";
import RemapRedirector from "modules/navigation/RemapRedirector";
import { PLAID_OAUTH_REDIRECT_URL } from "modules/plaid/queries/plaidLinkTokenQueryHooks";
import { useIsCapitalApplicationReferral } from "modules/user-referrals/queries/useReferral";
import DepositCheckPage from "pages/DepositCheckPage";
import PayeesPage from "pages/payments/PayeesPage";
import CreatePayeePage from "pages/payments/PayeesPage/CreatePayeePage";
import EditPayeePage from "pages/payments/PayeesPage/EditPayeePage";
import PayeeDetailsPage from "pages/payments/PayeesPage/PayeeDetailsPage";
import PaymentsOverviewPage from "pages/payments/PaymentsOverviewPage";
import PlaidOauthRedirectPage from "pages/PlaidOauthRedirectPage";
import SendMoneyPage from "pages/SendMoneyPage";
import TransferMoneyPage from "pages/TransferMoneyPage";
import { FC, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import OnboardingRoutes from "routes/OnboardingRoutes";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToPaymentsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToPaymentsRoutes";
import useIsAllowedToNavigateToSendMoney from "utils/permissions/navigation/useIsAllowedToNavigateToSendMoney";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToEditPayees from "utils/permissions/useIsAllowedToEditPayees";

import AccountsRoutes from "./AccountsRoutes";
import AiRoutes from "./AiRoutes";
import BillPayRoutesRenderer from "./BillPayRoutesRenderer";
import CapitalRoutes from "./CapitalRoutes";
import CardsRoutes from "./CardsRoutes";
import SettingsRoutes from "./SettingsRoutes";
import TasksRoutes from "./TasksRoutes";

const OnboardedRouter: FC = () => {
  const isAllowedToNavigateToSendMoney = useIsAllowedToNavigateToSendMoney();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isAllowedToNavigateToPaymentsRoutes = useIsAllowedToNavigateToPaymentsRoutes();
  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isAllowedToNavigateToChatRoutes = useIsAllowedToNavigateToChatRoutes();
  const isAllowedToEditPayee = useIsAllowedToEditPayees();

  const hasSkippedOnboarding = useHasSkippedOnboarding();
  const isBusinessActive = useIsBusinessActive();
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();
  const isCapitalApplicationReferral = useIsCapitalApplicationReferral();
  const mostRecentCreditApplication = useMostRecentCreditApplication();

  // NB(alex): Hack to stop making the index page the capital page for users who joined via capital application referral link. Otherwise the capital page will become this user's index page until they finish applying for banking.
  const shouldSkipOnboardingForCapitalReferral =
    isCapitalApplicationReferral && mostRecentCreditApplication;

  const indexRoute = useMemo(() => {
    if (
      hasSkippedOnboarding ||
      isBusinessActive ||
      hasFilledOutBankApplication ||
      shouldSkipOnboardingForCapitalReferral
    ) {
      return isAllowedToNavigateToAccountsRoutes ? "/accounts" : "/cards";
    } else {
      if (isCapitalApplicationReferral) {
        return "/capital";
      }
      return "/onboarding";
    }
  }, [
    hasFilledOutBankApplication,
    hasSkippedOnboarding,
    isAllowedToNavigateToAccountsRoutes,
    isBusinessActive,
    isCapitalApplicationReferral,
    shouldSkipOnboardingForCapitalReferral,
  ]);

  return (
    <Routes>
      <Route path="/businesses" element={<BusinessDisambiguator />} />

      <Route path="/onboarding/*" element={<OnboardingRoutes />} />

      <Route path="/home" element={<Navigate to={indexRoute} />} />

      <Route
        path="/accounts/*"
        element={
          <DashboardLayout>
            <AccountsRoutes indexRoute={indexRoute} />
          </DashboardLayout>
        }
      />

      <Route
        path="/cards/*"
        element={
          <DashboardLayout>
            <CardsRoutes />
          </DashboardLayout>
        }
      />

      <Route
        path="/payments/*"
        element={
          isAllowedToNavigateToPaymentsRoutes ? (
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<PaymentsOverviewPage />}>
                  <Route
                    path="/payment-approvals/:paymentApprovalGuid"
                    element={<PaymentsOverviewPage />}
                  />
                  <Route
                    path="/scheduled-payments/:recurringPaymentId"
                    element={<PaymentsOverviewPage />}
                  />
                </Route>
                <Route path="/payees" element={<PayeesPage />} />
                <Route path="/payees/new" element={<CreatePayeePage />} />
                <Route
                  path="/payees/:payeeGuid/edit"
                  element={
                    isAllowedToEditPayee ? (
                      <EditPayeePage />
                    ) : (
                      <Navigate
                        to="/payees" // NB(alex): we can also redirect to `/payees/:payeeGuid` but that takes more work so going to leave it like this for now.
                      />
                    )
                  }
                />
                <Route path="/payees/:payeeGuid/*" element={<PayeeDetailsPage />} />
              </Routes>
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />

      {/* We insert a pass-through route here in order to render a BillPayRoutesRenderer
      inside of an error boundary. BillPayRoutesRenderer provides the actual routes
      for bill pay. */}
      <Route
        path="/bills/*"
        element={
          <ErrorBoundary fallback={null}>
            <BillPayRoutesRenderer />
          </ErrorBoundary>
        }
      />

      <Route
        path="/cash-flow/*"
        element={<RemapRedirector oldPath="/cash-flow" newPath="/ai/vendor-rules" />}
      />

      <Route
        path="/vendor-rules/*"
        element={<RemapRedirector oldPath="/vendor-rules" newPath="/ai/vendor-rules" />}
      />

      <Route path="/chat/*" element={<RemapRedirector oldPath="/chat" newPath="/ai" />} />

      <Route
        path="/ai/*"
        element={
          isAllowedToNavigateToChatRoutes ? (
            <DashboardLayout>
              <AiRoutes />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} replace />
          )
        }
      />

      <Route
        path="/tasks/*"
        element={
          <DashboardLayout>
            <TasksRoutes />
          </DashboardLayout>
        }
      />

      <Route
        path="/capital/*"
        element={
          isAllowedToNavigateToCapitalRoutes ? (
            <DashboardLayout>
              <CapitalRoutes />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/settings/*"
        element={
          <DashboardLayout>
            <SettingsRoutes />
          </DashboardLayout>
        }
      />
      <Route
        path="/send-money"
        element={
          isAllowedToNavigateToSendMoney ? (
            <DashboardLayout>
              <SendMoneyPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/transfer-money"
        element={
          isAllowedToNavigateToTransferMoney ? (
            <DashboardLayout>
              <TransferMoneyPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/deposit-check"
        element={
          isAllowedToNavigateToDepositCheckPage ? (
            <DashboardLayout>
              <DepositCheckPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route path={PLAID_OAUTH_REDIRECT_URL} element={<PlaidOauthRedirectPage />} />
      {/* Redirects */}

      <Route path="/high-yield/*" element={<Navigate replace to={indexRoute} />} />
      <Route path="*" element={<Navigate replace to={indexRoute} />} />
    </Routes>
  );
};

export default OnboardedRouter;
